<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Dados do usuario</h2>
        <hr />
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="username"
                label="Nome de usuário (Obrigatório)"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                v-model="email"
                label="Email (Obrigatório)"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="setor"
                label="Setor (Obrigatório)"
                required
              ></v-text-field>
            </div>
          </div>

          <v-btn
            @click="atualizarUsuario"
            class="mr-4 textStyle"
            tile
            color="#1dd1a1"
          >
            <span style="color: white">Atualizar</span>
          </v-btn>

          <router-link to="/painelContasUsuarios" v-slot="{ href, navigate }">
            <a :href="href" class="menu-link" @click="navigate">
              <v-btn class=" textStyle" tile color="#1dd1a1">
                <span style="color: white">Voltar</span>
              </v-btn>
            </a>
          </router-link>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import editUser from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "EditarUsuario",
  async created() {
    try {
      const response = await ambulatorio.indexUsuario(this.$route.params.id);

      this.email = response.data.usuario.email;
      this.username = response.data.usuario.username;
      this.setor = response.data.usuario.setor.descricao;
      this.usuarioTipo = response.data.usuario.usuario_de_type;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      items: ["Baixa", "Média", "Alta"],
      email: "",
      setor: "",
      usernmae: "",
      usuarioTipo: "",
      options: [
        { value: 1, text: "Admin" },
        { value: 2, text: "Médico(a)" },
        { value: 3, text: "Enfermeiro(a)" },
        { value: 4, text: "Recepcionista(a)" }
      ]
    };
  },

  methods: {
    atualizarUsuario: async function() {
      const data = {
        usuario: {
          email: this.email
        }
      };

      try {
        const response = await ambulatorio.updateUsuario(
          this.$route.params.id,
          data
        );
        editUser.fire({
          title: "",
          text: "Atulização realizada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });

        console.log(response.data);
      } catch (error) {
        editUser.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
        console.log(error);
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Atualizar dados do usuário" }
    ]);
  }
};
</script>
